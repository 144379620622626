import {
  CommunicationPreference,
  RenewalEmailTemplate,
  PreferenceCenter,
  PreferenceCenterData,
  PreferenceCenterList,
  PreferenceCenterWidgetData,
  ConsentDataByCommunicationMethod,
  ConsentCategoryData,
  PreferenceCenterGeneralSettings,
  ConsentCategorySettings,
  PreferenceStyleConfig,
  PreferenceStyleConfigInitialState,
  PreferenceCenterCategoryData
} from './preferenceCenterSlice'
import {
  getConsentCategoryById,
  transformFormConfigArray,
  transformFormConfigArrayByConfigType
} from '../utils'
import { objectId } from '../../../utils'
import { gql } from 'graphql-request'

export interface PreferenceCenterQueryParams {
  id?: string
  before?: string
  after?: string
  first?: number
  last?: number
}

const preferenceCenterNodeFragment = gql`
  fragment PreferenceCenterNodeFragment on PreferenceCenter {
    id
    name
    regulations
    createdAt
    updateTimestamp
    associatedDomains
    isPublished
    preferenceCenterGroupId
    totalConsents: consentHistoryGroupByRequestId {
      count
    }
    preferenceConfig {
      preferenceCategoryId
      consentHistoryGroupByRequestId {
        count
      }
    }
  }
`

// #region Preference Center Query
export const queryPreferenceCenter = (id: string): string => {
  return gql`
    query {
      preferenceCenter(id: "${id}") {
        edges {
          node {
            id
            preferenceCenterGroupId
            name
            description
            regulations
            associatedDomains
            isPublished
            datasource{
              edges{
                node{
                  id
                  name
                  type
                }
              }
            }
            optOutSpecificModesOfCommunication
            optOutSpecificModesOfCommunicationType
            consentRenewal {
              forceOptIn
              interval
              intervalType
              sendEmailToUsers
              emailTemplateId
            }
            preferenceConfig {
              preferenceCategoryId
              preferenceCategory{
                edges{
                  node{
                    displayTitle
                    displayDescription
                    name
                  }
                }
              }
            }
            formConfig {
              edges {
                node {
                  formConfig {
                    configType
                    config {
                      key
                      values
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `
}
// #endregion

export const mapQueryPreferenceCenter = (raw: any) => {
  try {
    const preferenceCenter = raw?.preferenceCenter?.edges?.at(0)?.node

    if (!preferenceCenter) {
      return null
    }

    const formConfig = preferenceCenter.formConfig.edges[0].node.formConfig

    const generalSettings: PreferenceCenterGeneralSettings = {
      name: preferenceCenter.name,
      description: preferenceCenter.description || '',
      regulations: preferenceCenter.regulations || [],
      isPublished: !!preferenceCenter.isPublished,
      associatedDomains: preferenceCenter.associatedDomains.map((domain: string) => ({
        value: domain,
        id: objectId(),
        isValid: true
      })),
      periodicConsentRenewal: preferenceCenter?.consentRenewal?.intervalType ? true : false,
      consentRenewalSettings: {
        consentRenewalType: preferenceCenter?.consentRenewal?.forceOptIn ? 'OPT_IN' : 'OPT_OUT',
        consentRenewalPeriod: preferenceCenter?.consentRenewal?.interval || 1,
        consentRenewalPeriodUnit:
          preferenceCenter?.consentRenewal?.intervalType?.toUpperCase() || 'DAY',
        emailToUserAfterRenewal: preferenceCenter?.consentRenewal?.sendEmailToUsers || false,
        emailToUserAfterRenewalTemplateId:
          preferenceCenter?.consentRenewal?.emailTemplateId || undefined
      }
    }

    const consentCategories = getConsentCategories({
      formConfig,
      preferenceCenter
    })

    const styleConfig = getStyleConfig({
      formConfig,
      preferenceCenter
    })

    const selectedLinkedDataSources = preferenceCenter.datasource.edges.map(({ node }) => node.id)

    return {
      id: preferenceCenter.id,
      preferenceCenterGroupId: preferenceCenter.preferenceCenterGroupId,
      generalSettings,
      consentCategories,
      styleConfig,
      selectedLinkedDataSources
    }
  } catch (error) {
    return null
  }
}

export const queryPreferenceCenterList = (params: PreferenceCenterQueryParams): string => {
  const { id, before, after, first, last } = params
  const idParam = id ? `id: "${id}"` : ''
  const beforeParam = before ? `before: "${before}"` : ''
  const afterParam = after ? `after: "${after}"` : ''
  const firstParam = first ? `first: ${first}` : ''
  const lastParam = last ? `last: ${last}` : ''

  const queryParamsString = [idParam, beforeParam, afterParam, firstParam, lastParam]
    .filter(Boolean)
    .join(', ')

  const queryParams = queryParamsString ? `(${queryParamsString})` : ''
  return gql`
    query {
      preferenceCenter${queryParams} {
        count
        pageInfo {
          hasNextPage
          hasPreviousPage
          startCursor
          endCursor
        }
        edges {
          node {
            ...PreferenceCenterNodeFragment
          }
        }
      }
    }
    ${preferenceCenterNodeFragment}
  `
}

export const mapQueryPreferenceCenterList = (raw: any): PreferenceCenterList => {
  const preferenceCenters: PreferenceCenter[] = raw?.preferenceCenter?.edges?.map(
    ({ node: pc }) => ({
      id: pc.id || '',
      name: pc.name || '',
      regulations: pc.regulations || [],
      createdAt: pc.createdAt || '',
      updatedTimestamp: pc.updateTimestamp || '',
      associatedDomains: pc.associatedDomains?.filter((x) => x) || [],
      consentsLogged: pc.totalConsents.count || 0,
      consentCategoryCount: pc.preferenceConfig.length || 0,
      isPublished: pc.isPublished ?? false,
      preferenceCenterGroupId: pc.preferenceCenterGroupId || ''
    })
  )

  return {
    count: raw?.preferenceCenter?.count || 0,
    pageInfo: raw?.preferenceCenter?.pageInfo || {},
    list: preferenceCenters
  }
}

export const queryPreferenceCenterOverviewData = (id: string, groupId: string): string => {
  return gql`
    query {
      preferenceCenter(id: "${id}") {
        edges {
          node {
            id
            name
            associatedDomains
            regulations
            optOutSpecificModesOfCommunication
            optOutSpecificModesOfCommunicationType
            totalConsents: consentHistoryGroupByRequestId(filter:[{key:PREFERENCE_CENTER, values:["${groupId}"]}]){
              count
            }
            preferenceConfig {
              preferenceCategoryId
              preferenceCategory{
                edges{
                  node{
                    displayTitle
                    displayDescription
                    name
                  }
                }
              }
              # Consent Category OptIn
              optIn: consentHistoryGroupByRequestId(filter:[{key:OPT_STATUS, values:["SUBSCRIBED"]}, {key:PREFERENCE_CENTER, values:["${groupId}"]}]){
                count
              }

              # Consent Category OptOut
              optOut: consentHistoryGroupByRequestId(filter:[{key:OPT_STATUS, values:["UNSUBSCRIBED"]}, {key:PREFERENCE_CENTER, values:["${groupId}"]}]){
                count
              }
              preferences {
                edges {
                  node {
                    id
                    name
                    type
                    configuration {
                      ... on HubspotCommunicationPreference {
                        communicationMethod
                        name
                      }
                    }
                    datasource {
                      edges {
                        node {
                          id
                          name
                        }
                      }
                    }
                    optIn: consentHistoryGroupByRequestId(filter: [{key: OPT_STATUS, values: ["SUBSCRIBED"]}, {key: PREFERENCE_CENTER, values: ["${groupId}"]}]) {
                      count
                    }
                    optOut: consentHistoryGroupByRequestId(filter: [{key: OPT_STATUS, values: ["UNSUBSCRIBED"]}, {key: PREFERENCE_CENTER, values: ["${groupId}"]}]) {
                      count
                    }
                  }
                }
              }
            }
            formConfig {
              edges {
                node {
                  formConfig {
                    configType
                    config {
                      key
                      values
                    }
                  }
                }
              }
            }
          }
        }
      }
      totalOptIn: consentHistoryGroupByRequestId(filter:[{key:OPT_STATUS, values:["SUBSCRIBED"]}, {key:PREFERENCE_CENTER, values:["${groupId}"]}]){
        count
      }
      totalOptOut: consentHistoryGroupByRequestId(filter:[{key:OPT_STATUS, values:["UNSUBSCRIBED"]}, {key:PREFERENCE_CENTER, values:["${groupId}"]}]){
        count
      }
      totalIdentified: consentHistoryGroupByRequestId(filter:[{key:ENTITY_IDENTIFIED, values:["Identified"]}, {key:PREFERENCE_CENTER, values:["${groupId}"]}]){
        count
      }
      totalUnidentified: consentHistoryGroupByRequestId(filter:[{key:ENTITY_IDENTIFIED, values:["Unidentified"]}, {key:PREFERENCE_CENTER, values:["${groupId}"]}]){
        count
      }
      datasources {
        edges{
          node{
            id
            name
            type
            consentHistoryGroupByRequestId(filter:[{key:PREFERENCE_CENTER,values:"${groupId}"}]) {
              count
            }
          }
        }
      }
    }
  `
}

export const mapQueryPreferenceCenterOverviewData = (raw: any) => {
  if (!raw) {
    return null
  }

  const preferenceCenter = raw?.preferenceCenter?.edges?.at(0)?.node

  if (!preferenceCenter) {
    return null
  }

  const formConfig = preferenceCenter.formConfig.edges[0].node.formConfig

  const associatedDataSourcesIds: string[] = []

  // Get associated data sources ids
  for (const preference of preferenceCenter?.preferenceConfig) {
    for (const preferenceCommunication of preference?.preferences?.edges) {
      associatedDataSourcesIds.push(preferenceCommunication.node.datasource.edges[0].node.id)
    }
  }

  // Filter out associated data sources of preference center
  const associatedDataSources = raw.datasources.edges.filter((ds) =>
    associatedDataSourcesIds.includes(ds.node.id)
  )

  const consentCategories: ConsentCategoryData[] = preferenceCenter.preferenceConfig.map(
    (category) => {
      return {
        id: category.preferenceCategoryId,
        title: category.preferenceCategory.edges.at(0)?.node?.name || '-',
        consentData: {
          total: category.optIn.count + category.optOut.count,
          optIn: category.optIn.count,
          optOut: category.optOut.count
        },
        communicationPreferences: category.preferences.edges.map(({ node }) => ({
          id: node.id,
          name: node.name || node.configuration.name || '-',
          type: node.type || '',
          datasourceId: node.datasource.edges[0].node.id,
          datasourceName: node.datasource.edges[0].node.name,
          communicationMethod: node.configuration.communicationMethod,
          consentData: {
            total: node.optIn.count + node.optOut.count,
            optIn: node.optIn.count,
            optOut: node.optOut.count
          }
        }))
      }
    }
  )

  const consentDataByCommunicationMethod: ConsentDataByCommunicationMethod = {}

  for (const category of consentCategories) {
    for (const preference of category.communicationPreferences) {
      const communicationMethod = preference.communicationMethod || 'Unknown'
      if (!consentDataByCommunicationMethod[communicationMethod]) {
        consentDataByCommunicationMethod[communicationMethod] = {
          total: 0,
          optIn: 0,
          optOut: 0
        }
      }

      consentDataByCommunicationMethod[communicationMethod].total += preference.consentData.total
      consentDataByCommunicationMethod[communicationMethod].optIn += preference.consentData.optIn
      consentDataByCommunicationMethod[communicationMethod].optOut += preference.consentData.optOut
    }
  }

  const styleConfig = getStyleConfig({
    formConfig,
    preferenceCenter
  })

  const consentCategoriesForPreview = getConsentCategories({
    formConfig,
    preferenceCenter
  })

  const widgetData: PreferenceCenterWidgetData = {
    preferenceCenterId: preferenceCenter.id,
    associatedDomains: preferenceCenter.associatedDomains?.filter((x) => x) || [],
    totalCategories: preferenceCenter.preferenceConfig.length,
    consentWidgetData: {
      total: preferenceCenter.totalConsents.count,
      optIn: raw.totalOptIn.count,
      optOut: raw.totalOptOut.count
    },
    entitiesWidgetData: {
      identified: raw.totalIdentified.count,
      unidentified: raw.totalUnidentified.count,
      total: raw.totalIdentified.count + raw.totalUnidentified.count
    },
    consentCategories: consentCategories,
    associatedDataSources: associatedDataSources.map((ds) => ({
      id: ds.node.id,
      name: ds.node.name,
      type: ds.node.type,
      totalConsents: ds.node.consentHistoryGroupByRequestId.count
    })),
    consentDataByCommunicationMethod: consentDataByCommunicationMethod,
    styleConfig: styleConfig,
    consentCategoriesForPreview: consentCategoriesForPreview
  }

  return widgetData
}
// #endregion

// #region Communication Preference Query (Subscription List) and Template List
export const queryCommunicationPreferenceList = (datasourceIds: string[]) => gql`
  query {
    communicationPreferences (filter:[{key:DATASOURCE_IDS,values:${JSON.stringify(
      datasourceIds
    )}}]) {
      edges {
        node {
          id
          type
          datasource {
            edges {
              node {
                id
                name
                type
              }
            }
          }
          configuration {

            ... on StructuredDataCommunicationPreference{
              __typename
              name
              description
              communincationMethod: subscriptionType
            }

            ... on HubspotCommunicationPreference {
            __typename
              purpose
              name
              description
              communicationMethod
              isActive
            }
            ... on CustomCommunicationPreference {
                __typename
                purpose
                name
                description

                communicationMethods {
                  communicationMethod
                  displayCommunicationMethod
                }
              }
          }
          createdAt
          updatedTimestamp
        }
      }
    }
  }
`

// Config Mapper based on the __typename
const communicationPreferenceConfigMapper = {
  HubspotCommunicationPreference: (config: any) => ({
    purpose: config.purpose || '',
    name: config.name || '',
    description: config.description || '',
    communicationMethod: config.communicationMethod || '',
    isActive: config.isActive || false
  }),
  CustomCommunicationPreference: (config: any) => ({
    purpose: config.purpose || '',
    name: config.name || '',
    description: config.description || '',
    communicationMethod: config.communicationMethods[0].communicationMethod || ''
  }),
  StructuredDataCommunicationPreference: (config: any) => ({
    name: config.name || '',
    description: config.description || '',
    communicationMethod: config.communincationMethod || '',
    purpose: config?.purpose || ''
  })
}

export const mapQueryCommunicationPreferenceList = (raw: any) => {
  const communicationPreferences: CommunicationPreference[] = raw?.communicationPreferences?.edges?.map(
    ({ node: cp }) => ({
      id: cp.id,
      type: cp.datasource.edges[0].node.type || '',
      configuration: communicationPreferenceConfigMapper[cp.configuration.__typename](
        cp.configuration
      ),
      createdAt: cp.createdAt || '',
      updatedTimestamp: cp.updatedTimestamp || '',
      datasoruceId: cp.datasource.edges[0].node.id,
      datasourceName: cp.datasource.edges[0].node.name
    })
  )

  // Filter out communication preference which does not have type or configuration
  return communicationPreferences.filter((cp) => cp.type && cp.configuration)
}

export const queryRenewalEmailTemplateList = gql`
  query {
    dsrEmailTemplate(
      filter: [
        { key: REQUEST_TYPE, values: "consentManagement" }
        { key: EVENT_TYPE, values: ["CM_CONSENT_RENEWAL"] }
      ]
    ) {
      edges {
        node {
          id
          name
        }
      }
    }
  }
`

export const mapQueryRenewalEmailTemplateList = (raw: any) => {
  const emailTemplates: RenewalEmailTemplate[] =
    raw?.dsrEmailTemplate?.edges?.map(({ node }) => ({
      id: node.id,
      name: node.name
    })) || []
  return emailTemplates
}

// #endregion

// #region Save Preference Center Mutation (Create/Update)
export type ConfigInFormConfig = {
  configType:
    | 'header_config'
    | 'body_config'
    | 'footer_config'
    | 'input_config'
    | 'buttons_config'
    | 'logo_config'
    | 'style_config'
    | 'language_config'
    | 'preference_config'
    | 'consent_input_config'
    | 'communication_preference_config'
  config: ConfigOfConfig[]
}

export type ConfigOfConfig = {
  key:
    | 'background_color'
    | 'border_color'
    | 'text_color'
    | 'logo'
    | 'font'
    | 'font_size'
    | 'bold'
    | 'italic'
    | 'highlight_color'
    | 'indentation'
    | 'submit_button'
    | 'cancel_button'
    | 'submit_background_color'
    | 'submit_border_color'
    | 'submit_text_color'
    | 'cancel_background_color'
    | 'cancel_border_color'
    | 'cancel_text_color'
    | 'on_color'
    | 'off_color'
    | 'always_on_color'
    | 'label'
    | 'other'
    | 'description'
    | 'title'
    | 'preference_category_id'
    | 'allow_visitor_opt_out'
    | 'communication_preference_ids'
    | 'preference_config_id'
    | 'send_link_text_color'
    | 'send_link_background_color'
    | 'send_link_border_color'
    | 'id'
    | 'unsubscribe_all_border_color'
    | 'unsubscribe_all_background_color'
    | 'unsubscribe_all_text_color'
    | 'show_last_updated'
    | 'allow_user_to_leave_comment'
    | 'enable_cancel_button'
    | 'enable_unsubscribe_button'
  values: (string | undefined | boolean | number)[]
}
export const mutationSavePreferenceCenter = (data: PreferenceCenterData) => {
  let mutationName = 'createPreferenceCenter'
  let preferenceCenterGroupIdParam = data.preferenceCenterGroupId
  let preferenceCenterIdParam = ''

  if (data.preferenceCenterId && data.createPreferenceCenterMode == 'EDIT') {
    mutationName = 'updatePreferenceCenter'
    preferenceCenterIdParam = `preferenceCenterId: "${data.preferenceCenterId}"`
    preferenceCenterGroupIdParam = ''
  }

  const { generalSettings, styleConfig, consentCategories, selectedLinkedDataSources } = data

  const consentCategoriesFormInput: ConfigInFormConfig[] = consentCategories
    .filter((category) => category.associatedSubscriptions?.length > 0)
    .map((category) => ({
      configType: 'preference_config',
      config: [
        {
          key: 'preference_config_id',
          values: [category.id]
        },
        {
          key: 'allow_visitor_opt_out',
          values: [category.allowOptOut]
        },
        {
          key: 'title',
          values: [category.displayTitle]
        },
        {
          key: 'description',
          values: [category.displayDescription]
        },
        {
          key: 'communication_preference_ids',
          values:
            category.associatedSubscriptions?.map((subscription) => subscription.subscriptionId) ||
            []
        }
      ]
    }))

  const subscriptionConfig = consentCategories
    .filter((category) => category.associatedSubscriptions?.length > 0)
    .reduce((acc, category) => {
      const communicationPreferenceConfig: ConfigInFormConfig[] =
        category.associatedSubscriptions?.map((sub) => ({
          configType: 'communication_preference_config',
          config: [
            {
              key: 'id',
              values: [sub.subscriptionId]
            },
            {
              key: 'label',
              values: [sub.displayName]
            }
          ]
        })) || []
      return [...acc, ...communicationPreferenceConfig]
    }, [] as ConfigInFormConfig[])

  const headerConfig: ConfigInFormConfig = {
    configType: 'header_config',
    config: [
      {
        key: 'logo',
        values: [styleConfig.headerLogo || '']
      },
      {
        key: 'background_color',
        values: ['#' + styleConfig.headerBackgroundColor]
      },
      {
        key: 'border_color',
        values: ['#' + styleConfig.headerBorderColor]
      }
    ]
  }

  const bodyAndInputConfig: ConfigInFormConfig[] = [
    {
      configType: 'body_config',
      config: [
        {
          key: 'id',
          values: ['EMAIL']
        },
        {
          key: 'background_color',
          values: ['#' + styleConfig.bodyBackgroundColor]
        },
        {
          key: 'text_color',
          values: ['#' + styleConfig.bodyTextColor]
        },
        {
          key: 'border_color',
          values: ['#' + styleConfig.bodyBorderColor]
        },
        {
          key: 'other', // Email Body Data
          values: [styleConfig.emailBodyContent || '']
        }
      ]
    },
    {
      configType: 'body_config',
      config: [
        {
          key: 'id',
          values: ['PREFERENCE_MANAGEMENT']
        },
        {
          key: 'background_color',
          values: ['#' + styleConfig.bodyBackgroundColor]
        },
        {
          key: 'text_color',
          values: ['#' + styleConfig.bodyTextColor]
        },
        {
          key: 'border_color',
          values: ['#' + styleConfig.bodyBorderColor]
        },
        {
          key: 'other', // Preference Body Data
          values: [styleConfig.preferenceBodyContent || '']
        }
      ]
    },
    {
      configType: 'footer_config',
      config: [
        {
          key: 'id',
          values: ['EMAIL_FOOTER']
        },
        {
          key: 'other', // Footer Data
          values: [styleConfig.emailFooterContent || '']
        }
      ]
    },
    {
      configType: 'footer_config',
      config: [
        {
          key: 'id',
          values: ['PREFERENCE_MANAGEMENT_FOOTER']
        },
        {
          key: 'other', // Footer Data
          values: [styleConfig.preferenceFooterContent || '']
        }
      ]
    },
    {
      configType: 'input_config',
      config: [
        {
          key: 'text_color',
          values: ['#' + styleConfig.emailInputsTextColor]
        },
        {
          key: 'background_color',
          values: ['#' + styleConfig.emailInputsBackgroundColor]
        },
        {
          key: 'border_color',
          values: ['#' + styleConfig.emailInputsBorderColor]
        }
      ]
    }
  ]

  const buttonConfig: ConfigInFormConfig = {
    configType: 'buttons_config',
    config: [
      // Send Link Button
      {
        key: 'send_link_background_color',
        values: ['#' + styleConfig.buttonsSendLinkBackgroundColor]
      },
      {
        key: 'send_link_text_color',
        values: ['#' + styleConfig.buttonsSendLinkTextColor]
      },
      {
        key: 'send_link_border_color',
        values: ['#' + styleConfig.buttonsSendLinkBorderColor]
      },

      // Submit Button
      {
        key: 'submit_background_color',
        values: ['#' + styleConfig.buttonsSubmitBackgroundColor]
      },
      {
        key: 'submit_text_color',
        values: ['#' + styleConfig.buttonsSubmitTextColor]
      },
      {
        key: 'submit_border_color',
        values: ['#' + styleConfig.buttonsSubmitBorderColor]
      },

      // Cancel Button
      {
        key: 'enable_cancel_button',
        values: [styleConfig.enableButtonCancel]
      },
      {
        key: 'cancel_background_color',
        values: ['#' + styleConfig.buttonsCancelBackgroundColor]
      },
      {
        key: 'cancel_text_color',
        values: ['#' + styleConfig.buttonsCancelTextColor]
      },
      {
        key: 'cancel_border_color',
        values: ['#' + styleConfig.buttonsCancelBorderColor]
      }
    ]
  }

  const consentInputConfig: ConfigInFormConfig = {
    configType: 'consent_input_config',
    config: [
      {
        key: 'on_color',
        values: ['#' + styleConfig.consentInputsOnColor]
      },
      {
        key: 'off_color',
        values: ['#' + styleConfig.consentInputsOffColor]
      },
      {
        key: 'always_on_color',
        values: ['#' + styleConfig.consentInputsAlwaysOnColor]
      },
      {
        key: 'border_color',
        values: ['#' + styleConfig.consentInputsBorderColor]
      },
      {
        key: 'allow_user_to_leave_comment',
        values: [styleConfig.enableCommentBox]
      },
      {
        key: 'show_last_updated',
        values: [styleConfig.showLastUpdate]
      }
    ]
  }

  const preferenceCenterInput = {
    name: generalSettings.name,
    description: generalSettings.description,
    regulations: generalSettings.regulations,
    associatedDomains: generalSettings.associatedDomains.map((domain) => domain.value),
    datasourceIds: selectedLinkedDataSources,
    optOutSpecificModesOfCommunication: styleConfig.enableCommunicationMode,
    optOutSpecificModesOfCommunicationType: styleConfig.optOutCommunicationMode,
    consentRenewal: generalSettings.periodicConsentRenewal
      ? {
          forceOptIn: generalSettings.consentRenewalSettings.consentRenewalType === 'OPT_IN',
          interval: generalSettings.consentRenewalSettings.consentRenewalPeriod,
          intervalType: generalSettings.consentRenewalSettings.consentRenewalPeriodUnit.toLowerCase(),
          sendEmailToUsers: generalSettings.consentRenewalSettings.emailToUserAfterRenewal,
          emailTemplateId: generalSettings.consentRenewalSettings.emailToUserAfterRenewalTemplateId
        }
      : null,
    preferenceConfig: consentCategories
      .filter((category) => category.associatedSubscriptions?.length > 0)
      .map((category) => ({
        preferenceCategoryId: category.id,
        preferenceConfigIds:
          category.associatedSubscriptions?.map((sub) => sub.subscriptionId) || []
      })),
    formConfig: {
      config: [
        ...consentCategoriesFormInput,
        ...subscriptionConfig,
        headerConfig,
        ...bodyAndInputConfig,
        buttonConfig,
        consentInputConfig
      ] as ConfigInFormConfig[]
    }
  }

  return gql`
    mutation {
      ${mutationName}(
        clientMutationId: "1"
        ${preferenceCenterIdParam}
        preferenceCenterInput: {
          name: "${preferenceCenterInput.name}"
          ${
            preferenceCenterGroupIdParam &&
            `preferenceCenterGroupId: "${preferenceCenterGroupIdParam}"`
          }
          description: "${preferenceCenterInput.description || ''}"
          regulations: ${JSON.stringify(preferenceCenterInput.regulations)}
          associatedDomains: ${JSON.stringify(preferenceCenterInput.associatedDomains)}
          datasourceIds: ${JSON.stringify(preferenceCenterInput.datasourceIds)}
          optOutSpecificModesOfCommunication: ${
            preferenceCenterInput.optOutSpecificModesOfCommunication
          }
          optOutSpecificModesOfCommunicationType: ${
            preferenceCenterInput.optOutSpecificModesOfCommunicationType
          }
          consentRenewal: ${
            preferenceCenterInput.consentRenewal
              ? `{
            forceOptIn: ${preferenceCenterInput.consentRenewal.forceOptIn}
            interval: ${preferenceCenterInput.consentRenewal.interval}
            intervalType: ${preferenceCenterInput.consentRenewal.intervalType}
            sendEmailToUsers: ${preferenceCenterInput.consentRenewal.sendEmailToUsers}
            ${
              preferenceCenterInput.consentRenewal.emailTemplateId
                ? `emailTemplateId: "${preferenceCenterInput.consentRenewal.emailTemplateId}"`
                : ''
            }
          }`
              : '{}'
          }
          preferenceConfig: ${toGraphQLInput(preferenceCenterInput.preferenceConfig)}
          formConfig: ${toGraphQLInput(preferenceCenterInput.formConfig)}
          geoLocation: []
        }
      ) {
        clientMutationId
        preferenceCenterId
      }
    }
  `
}
// #endregion

// #region Delete Preference Center Mutation
export const mutationDeletePreferenceCenter = (id: string) => {
  return gql`
    mutation {
      deletePreferenceCenter(clientMutationId: "1", preferenceCenterId: "${id}") {
        clientMutationId
        preferenceCenterId
      }
    }
  `
}
// #endregion

// #region Publish Preference Center Mutation
export const mutationPublishPreferenceCenter = (id: string) => {
  return gql`
    mutation {
      publishPreferenceCenter(
        clientMutationId: "123"
        preferenceCenterId: "${id}"
      ) {
        clientMutationId
        isPublished
      }
    }
  `
}
// #endregion

// #region Unpublish Preference Center Mutation

export const mutationUnpublishPreferenceCenter = (id: string) => {
  return gql`
  mutation{
    unpublishPreferenceCenter(clientMutationId: "123"
    preferenceCenterId: "${id}"
    ){
      clientMutationId
      isPublished
    }
  }
  `
}
// #endregion

function getConsentCategories({ formConfig, preferenceCenter }) {
  const formConfigArr = transformFormConfigArray(formConfig)

  const consentCategories: ConsentCategorySettings[] = []

  for (const category of preferenceCenter.preferenceConfig) {
    const categoryFormConfig = getConsentCategoryById({
      preferenceCategoryId: category.preferenceCategoryId,
      formConfig
    })

    if (!categoryFormConfig) {
      continue
    }

    let associatedSubscriptions = [
      {
        subscriptionId: '',
        displayName: ''
      }
    ]

    // Check if communication_preference_ids is array or string
    if (typeof categoryFormConfig.communication_preference_ids === 'string') {
      associatedSubscriptions = [
        {
          subscriptionId: categoryFormConfig.communication_preference_ids,
          displayName:
            formConfigArr?.find(
              (item) => item.id === categoryFormConfig.communication_preference_ids
            )?.label || ''
        }
      ]
    } else if (Array.isArray(categoryFormConfig.communication_preference_ids)) {
      associatedSubscriptions = categoryFormConfig.communication_preference_ids?.map(
        (subscriptionId) => ({
          subscriptionId,
          displayName: formConfigArr?.find((item) => item.id === subscriptionId)?.label || ''
        })
      )
    }

    consentCategories.push({
      id: category.preferenceCategoryId,
      displayTitle: category?.preferenceCategory.edges.at(0)?.node?.displayTitle || '-',
      displayDescription:
        decodeURIComponent(category?.preferenceCategory.edges.at(0)?.node?.displayDescription) ||
        '-',
      categoryName: category?.preferenceCategory.edges.at(0)?.node?.name || '-',
      allowOptOut: JSON.parse(categoryFormConfig.allow_visitor_opt_out || 'false'),
      associatedSubscriptions: associatedSubscriptions
    })
  }

  return consentCategories
}

function getStyleConfig({ preferenceCenter, formConfig }) {
  const formConfigObj = transformFormConfigArrayByConfigType(formConfig)
  const formConfigArr = transformFormConfigArray(formConfig)

  const headerConfig = formConfigObj.header_config
  const inputConfig = formConfigObj.input_config
  const consentInput = formConfigObj.consent_input_config
  const buttonConfig = formConfigObj.buttons_config
  const emailBodyConfig = formConfigArr.find((item) => item?.id === 'EMAIL')
  const preferenceManagementBodyConfig = formConfigArr.find(
    (item) => item?.id === 'PREFERENCE_MANAGEMENT'
  )
  const emailFooterConfig = formConfigArr.find((item) => item?.id === 'EMAIL_FOOTER')
  const preferenceManagementFooterConfig = formConfigArr.find(
    (item) => item?.id === 'PREFERENCE_MANAGEMENT_FOOTER'
  )

  const styleConfig: PreferenceStyleConfig = {
    ...PreferenceStyleConfigInitialState,
    ...{
      headerLogo: headerConfig.logo || undefined,
      headerBackgroundColor: headerConfig.background_color?.replace('#', '') || undefined,
      headerBorderColor: headerConfig.border_color?.replace('#', '') || undefined,

      bodyBackgroundColor: emailBodyConfig?.background_color?.replace('#', '') || undefined,
      bodyTextColor: emailBodyConfig?.text_color?.replace('#', '') || undefined,

      emailInputsBackgroundColor: inputConfig.background_color?.replace('#', '') || undefined,
      emailInputsBorderColor: inputConfig.border_color?.replace('#', '') || undefined,
      emailInputsTextColor: inputConfig.text_color?.replace('#', '') || undefined,

      consentInputsOnColor: consentInput.on_color?.replace('#', '') || undefined,
      consentInputsOffColor: consentInput.off_color?.replace('#', '') || undefined,
      consentInputsAlwaysOnColor: consentInput.always_on_color?.replace('#', '') || undefined,
      consentInputsBorderColor: consentInput.border_color?.replace('#', '') || undefined,
      showLastUpdate: JSON.parse(consentInput.show_last_updated || 'false'),

      buttonsSendLinkBackgroundColor:
        buttonConfig.send_link_background_color?.replace('#', '') || undefined,
      buttonsSendLinkBorderColor:
        buttonConfig.send_link_border_color?.replace('#', '') || undefined,
      buttonsSendLinkTextColor: buttonConfig.send_link_text_color?.replace('#', '') || undefined,

      buttonsSubmitBackgroundColor:
        buttonConfig.submit_background_color?.replace('#', '') || undefined,
      buttonsSubmitBorderColor: buttonConfig.submit_border_color?.replace('#', '') || undefined,
      buttonsSubmitTextColor: buttonConfig.submit_text_color?.replace('#', '') || undefined,

      buttonsCancelBackgroundColor:
        buttonConfig.cancel_background_color?.replace('#', '') || undefined,
      buttonsCancelBorderColor: buttonConfig.cancel_border_color?.replace('#', '') || undefined,
      buttonsCancelTextColor: buttonConfig.cancel_text_color?.replace('#', '') || undefined,
      enableButtonCancel: JSON.parse(buttonConfig.enable_cancel_button || 'false'),

      enableCommunicationMode: JSON.parse(
        preferenceCenter.optOutSpecificModesOfCommunication || 'false'
      ),
      optOutCommunicationMode: preferenceCenter.optOutSpecificModesOfCommunicationType || undefined,

      enableCommentBox: JSON.parse(consentInput.allow_user_to_leave_comment || 'false'),

      emailBodyContent: emailBodyConfig?.other || undefined,
      emailFooterContent: emailFooterConfig?.other || undefined,
      preferenceBodyContent: preferenceManagementBodyConfig?.other || undefined,
      preferenceFooterContent: preferenceManagementFooterConfig?.other || undefined
    }
  }

  return styleConfig
}

function toGraphQLInput(obj) {
  const formatValue = (value, key = '') => {
    if (key == 'key' || key == 'configType') {
      return value
    }

    if (typeof value === 'string' && key !== 'key' && key !== 'configType') {
      return `${JSON.stringify(value)}`
    }

    if (typeof value === 'boolean') {
      return `"${value}"`
    }

    if (Array.isArray(value)) {
      return `[${value.map((val) => formatValue(val, key)).join(', ')}]`
    }
    if (typeof value === 'object' && value !== null) {
      return `{ ${Object.entries(value)
        .map(([k, val]) => `${k}: ${formatValue(val, k)}`)
        .join(', ')} }`
    }
    return `${JSON.stringify(value)}`
  }

  return formatValue(obj)
}

export const queryCategoryList = () => gql`
  {
    preferenceCategory {
      edges {
        node {
          id
          name
          createdBy
          createdAt
          displayTitle
          displayDescription
        }
      }
    }
  }
`

export const mapQueryCategoryList = (raw: any) => {
  const preferenceCategories: PreferenceCenterCategoryData[] = []

  if (!raw?.preferenceCategory?.edges) {
    return preferenceCategories
  }

  for (const category of raw?.preferenceCategory?.edges) {
    preferenceCategories.push({
      id: category?.node?.id,
      categoryName: category?.node?.name,
      createdBy: category?.node?.createdBy,
      createdAt: category?.node?.createdAt,
      displayTitle: category?.node?.displayTitle,
      displayDescription: decodeURIComponent(category?.node?.displayDescription)
    })
  }

  return preferenceCategories
}
