import { ScanListItem } from './components/ScanSettings/scanSettings'
import {
  ACTIVE_TAB,
  IS_AT_RISK,
  IS_SENSITIVE,
  IS_ALL,
  SORT_BY,
  SORT_DIRECTION,
  PAGE,
  DATA_SOURCE_ID,
  DATABASE_ID,
  DRIVE_ID,
  TABLE_ID,
  SEVERITY_LEVEL,
  DATA_SOURCE_TYPES,
  POLICY_TYPES,
  CHANNEL_TYPES,
  ALERT_STATUS,
  REGULATIONS,
  SHARE_ID,
  TEMPLATE_ID,
  DownloadReportFormats,
  DownloadReportTypes,
  POLICY_CLASSIFICATIONS,
  AccessLevelRoles,
  UserRoles,
  ALERT_ASSIGMENT_OPTIONS,
  USER_ID,
  MAIL_FOLDER_TYPE,
  ACCESS_CONTROL_TYPES,
  OPEN_ACCESS_TYPES,
  GROUP_ID,
  RETENTION_ATTRIBUTE_TYPES
} from './constants'
import { AccessControlUserListItem } from './features/accessControl/types'
import { AttributeSet } from './features/attributes/attributesSlice'
import { COLUMN_CHANGES } from './features/columns/columnsSlice'
import { DocumentLabel } from './features/documentLabelsV2/types'
import { FileShareObjectValue } from './features/fileShares/fileSharesSlice'
import {
  AlertAttributeCondition,
  DatasourceScanConditionsFilter
} from './features/policies/policiesSlice'
import { AutomationConfig } from './features/policies/v2/components/Step4/types'
import {
  Document,
  DocumentApiQueryParams,
  EntitiesQueryParams,
  GetTextLabels,
  GetTextLabelsParams,
  GroupQueryParams,
  NamedTimeSeriesItem,
  ResidenciesQueryParams,
  DriveApiQueryParams,
  UserApiQueryParams,
  LocationApiQueryParams,
  ViolationApiQueryParams,
  EmailApiQueryParams,
  ImMessageApiQueryParams,
  IdentifiersParams,
  UserEntity,
  DataSource,
  BoundingBoxTag,
  UnlinkedAttribute
} from './services/api/apiTypes'
import { CriteriaGroupOperatorTypes, CriteriaOperatorTypes, Ruleset } from './utils/rulesetUtils'

export interface APIResponseMsg {
  success?: string
  error?: string
}

export enum LOADING {
  idle = 'idle',
  pending = 'pending'
}

export enum STATES {
  success = 'success',
  pending = 'pending',
  failed = 'failed'
}

export enum SORT_ORDER {
  ASC = 'ascending',
  DESC = 'descending'
}

/** Components */
export enum WIDGET_TOTAL_CONTENT_TYPE {
  CHART = 'chart',
  COLUMNS = 'columns'
}

/** Histograms */
export interface ChartTimeseriesItem extends NamedTimeSeriesItem {
  dateTime?: string
  color?: string
}

export interface ChartTimeseriesItemMultiple {
  units: string | null
  timestamp: number
  dateTime: string
  value1: number
  value2: number
  value3?: number
  value4?: number
  value5?: number
  name?: string
}

/** Document annotator */
export type IBoundingBoxCoordinates = {
  leftUpperX1: number
  leftUpperY1: number
  rightLowerX2: number
  rightLowerY2: number
  pageNumber?: number
}

export type IScaleCoefficients = {
  width: number
  height: number
}

export interface BoundingBoxUI extends BoundingBoxTag {
  documentId?: string
  localId?: string
  coordinates?: IBoundingBoxCoordinates
  isMasked?: boolean
}

export type UnlinkedAttributeUI = UnlinkedAttribute & {
  localId: string
  documentId: string
  coordinates?: IBoundingBoxCoordinates
  isMasked: boolean
}

interface IPublishDocumentParams {
  document: Document
  boundingBoxes: BoundingBoxUI[]
}

export type IPublishDClassifiedDocumentParams = IPublishDocumentParams

export interface IPublishDUnClassifiedDocumentParams extends IPublishDocumentParams {
  annotation: string
}

export interface IEditableBoundingBoxValues {
  identifierId: string
  identifierName: string
  value: string
  confidence: number
  context: string
}

export interface IBoundingBoxValuesToUpdate extends Partial<IEditableBoundingBoxValues> {
  localId: string
  isMasked?: boolean
}

export enum ANNOTATOR_TABS_TABLE {
  attributes = 'attributes',
  json = 'json'
}

export enum ANNOTATOR_TABS_CONVERSATION {
  TEXT = 'text',
  ATTACHMENTS = 'attachments'
}

export enum ANNOTATION_TYPES {
  IM = 'im',
  EMAIL = 'email',
  TICKET = 'ticket'
}

export interface IContextItem {
  [key: string]: {
    isCollapsed: boolean
    boundingBoxes: BoundingBoxUI[]
  }
}

export interface IdentifiersInSelectedArea {
  coordinates: GetTextLabelsParams['boundingBox']
  documentId: Document['id']
  result: GetTextLabels | null
  isPending: boolean
  pageNumber?: number
}

export interface IDocumentDimensions {
  widthOriginal: number
  heightOriginal: number
  widthScaled: number
  heightScaled: number
}

/** Classification */
export interface IGetDocumentsParams extends DocumentApiQueryParams {
  page?: number
  datasourceType?: string
  ticketId?: string
  category?: string
}

export interface ObjectsWidget {
  riskCount: number
  sensitiveCount: number
  totalCount: number
}

export interface AlertsListItem {
  type: string
  message: string
  isNew?: boolean
}

export interface AlertListingWidgetItem {
  list: Array<AlertsListItem>
  timestamp: number
  newCount?: number
}

export interface ViolationListItem {
  type: string
  message: string
  isNew?: boolean
}

export interface ViolationListingWidgetItem {
  list: Array<ViolationListItem>
  timestamp: number
  newCount?: number
}

export interface FileTotalHeaders {
  totalCount: number
  syncedCount: number
}

/** Context */
export interface IContextFilters {
  datasourceId?: string
  clusterId?: string
  driveId?: string
  annotationId?: string
  userEntityId?: string
  isAtRisk?: boolean
  isSensitive?: boolean
}
export interface IContextDisplayFilters {
  datasourceName?: string
  annotationName?: string
  driveId?: string
  userEntityName?: string
}
export interface IContextParams {
  pageName?: string
  pageUrl?: string
  filters?: IContextFilters
  displayFilters?: IContextDisplayFilters
}

/** Context v2 */
export type ContextPageFilters = {
  [IS_AT_RISK]?: boolean
  [IS_SENSITIVE]?: boolean
  [IS_ALL]?: boolean
  [ACTIVE_TAB]?: string
  [SORT_DIRECTION]?: SORT_ORDER
  [SORT_BY]?: string
  [PAGE]?: number
  [DATABASE_ID]?: string
}

export type ContextFilters = {
  annotationId?: string
  templateName?: string
  userEntityId?: string
  [DATA_SOURCE_ID]?: string
  [USER_ID]?: string
  [GROUP_ID]?: string
  [DRIVE_ID]?: string
  mailId?: string
  folderId?: string
  messageId?: string
  location?: string
  residency?: string
  entityName?: string
  entityType?: string
  [DATABASE_ID]?: string
  [TABLE_ID]?: string
  bucketId?: string
  [SHARE_ID]?: string
  [TEMPLATE_ID]?: string
  blobId?: string
}

/** Menu filters */
export interface IMenuFiltersItem {
  image?: React.ReactElement
  imageActive?: React.ReactElement
  link?: string
  beta?: boolean
  imagePath?: string
  icon?: string
  text?: string
  isActive?: boolean
  className?: string
  type?: string
  isHidden?: boolean
  children?: IMenuFiltersItem[]
  validateActive?: (match, location) => boolean
}

/** Hybrid widget sensitive info */
export interface ISensitiveInfoChartCombined {
  entities?: ChartTimeseriesItemMultiple[]
  messages?: ChartTimeseriesItemMultiple[]
  files?: ChartTimeseriesItemMultiple[]
  residencies?: ChartTimeseriesItemMultiple[]
}

export interface ISensitiveInfoChartTotal {
  entities?: { atRisk: number; total: number }
  messages?: { atRisk: number; total: number }
  files?: { atRisk: number; total: number }
  residencies?: { atRisk: number; total: number }
  emails?: { atRisk: number; total: number }
}

/** Breadcrumb */
export interface IBreadcrumb {
  url: string
  name: string
  nameTrimmed?: string
  isTranslate: boolean
  search: string
}

/** Entities */
export interface IGetEntitiesParams extends EntitiesQueryParams {
  page: number
  pageSize?: number
  filters?: FilterParams
  isJira?: boolean
  isAws?: boolean
  attributeFilters?: any
}

export interface EntitiesWidget {
  riskCount: number
  sensitiveCount: number
  totalCount: number
}

/** Users */
export interface IGetUsersParams extends UserApiQueryParams {
  page?: number
  pageSize?: number
  searchTerm?: string
  isAtRisk?: boolean
  isSensitive?: boolean
}

/** Groups */
export interface IGetGroupsParams extends GroupQueryParams {
  page: number
}

/** Residencies */
export interface IGetResidenciesParams extends ResidenciesQueryParams {
  page: number
  isAtRisk?: boolean
  isSensitive?: boolean
}

/** Drives */
export interface IGetDrivesParams extends DriveApiQueryParams {
  page?: number
  pageSize?: number
  filters?: FilterParams
  searchQuery?: string
}

/** Locations */
export interface IGetLocationsParams extends LocationApiQueryParams {
  page: number
}

/** Data source settings */
export enum ConnectionStatus {
  unset = 'unset',
  success = 'success',
  error = 'error'
}

export enum ScanStatus {
  registering = 'registering',
  scanning = 'scanning',
  Scanning = 'scanning',
  completed = 'completed',
  scanned = 'completed',
  failed = 'failed',
  warning = 'warning',
  connected = 'scanning',
  disconnected = 'disconnected',
  deleting = 'deleting',
  paused = 'paused',
  pausing = 'pausing',
  resuming = 'resuming'
}

export enum ScanConditionsDataSourceTypes {
  'structured' = 'structured',
  'unstructured' = 'unstructured',
  'bigquery' = 'bigquery',
  'airbyte' = 'airbyte',
  'looker' = 'looker',
  'databricks' = 'databricks'
}
export enum ScanConditionsFilterTypes {
  include = 'include',
  exclude = 'exclude'
}
export enum ScanConditionsObjectTypes {
  channel = 'channel',
  drive = 'drive',
  sharedDrive = 'sharedDrive',
  mailBox = 'mailbox',
  mailGroup = 'mailGroup',
  jira = 'ticketStoreProject',
  bucketId = 'bucket',
  folder = 'folder',
  site = 'site',
  sharePath = 'sharePath',
  teamsChannels = 'teamsChannel'
}
export enum ScanConditionsObjectTypesRest {
  channel = 'CHANNEL',
  drive = 'DRIVE',
  sharedDrive = 'SHARED_DRIVE',
  mailbox = 'MAILBOX',
  mailGroup = 'MAIL_GROUP',
  ticketStoreProject = 'TICKET_STORE_PROJECT',
  bucket = 'BUCKET',
  folder = 'FOLDER',
  site = 'SITE',
  sharePath = 'SHARE_PATH'
}

export enum ScanConditionsDsFilterTypes {
  include = 'INCLUDE',
  exclude = 'EXCLUDE'
}

export const mapScanConditionsObjectTypeFromRestToGraphQl = (objectType) => {
  if (objectType === ScanConditionsObjectTypesRest.channel) {
    return ScanConditionsObjectTypes.channel
  }
  if (objectType === ScanConditionsObjectTypesRest.bucket) {
    return ScanConditionsObjectTypes.bucketId
  }
  if (objectType === ScanConditionsObjectTypesRest.folder) {
    return ScanConditionsObjectTypes.folder
  }
  if (objectType === ScanConditionsObjectTypesRest.ticketStoreProject) {
    return ScanConditionsObjectTypes.jira
  }
  if (objectType === ScanConditionsObjectTypesRest.mailbox) {
    return ScanConditionsObjectTypes.mailBox
  }
  if (objectType === ScanConditionsObjectTypesRest.mailGroup) {
    return ScanConditionsObjectTypes.mailGroup
  }
  if (objectType === ScanConditionsObjectTypesRest.drive) {
    return ScanConditionsObjectTypes.drive
  }
  if (objectType === ScanConditionsObjectTypesRest.sharedDrive) {
    return ScanConditionsObjectTypes.sharedDrive
  }
  if (objectType === ScanConditionsObjectTypesRest.site) {
    return ScanConditionsObjectTypes.site
  }
  if (objectType === ScanConditionsObjectTypesRest.sharePath) {
    return ScanConditionsObjectTypes.sharePath
  }

  return ''
}

export interface ContainerObjectValue {
  subscriptionId: string
  storageAccountId: string
  storageAccountName: string
  containerNames: string[]
}

export type TeamsScanItem = {
  teamsChannelId?: string
  teamsChannelName?: string
  teamName?: string
  teamsChannelType?: CHANNEL_TYPES
  teamId?: string
}

export type UnstructuredScanConditions = {
  type: ScanConditionsDataSourceTypes
  filterType: ScanConditionsFilterTypes
  objectType: ScanConditionsObjectTypes
  objectMetadata?: ScanListItem[]
  objectValues?: string[] | FileShareObjectValue[] | ContainerObjectValue[] | TeamsScanItem[]
}

/** Violations */
export interface IGetViolationsParams extends ViolationApiQueryParams {
  page: number
}

/** Templates */
export enum TEMPLATES_TABLE_TABS {
  published = 'published',
  draft = 'draft'
}

/** Emails */
export interface IGetEmailParams extends EmailApiQueryParams {
  page: number
  pageSize?: number
  filter?: FilterParams
  activeTab?: string
}

/** ALerts and anomalies tab */
export enum ALERTS_ANOMALIES_TABS {
  ALERTS = 'ALERTS',
  ANOMALIES = 'ANOMALIES'
}

/** Conversations */
export interface IGetMessagesParams extends ImMessageApiQueryParams {
  page: number
  pageSize?: number
  channelType?: string
  channelId?: string
  entityIds?: string
  entityId?: string
  teamId?: string
}

export interface IDataSourceCommonFields {
  name: string
  description?: string
  location?: string
  purpose?: string
  stage?: string
  labels?: DocumentLabel[]
  schedulePolicy?: string
  createdBy?: string[]
  owners?: string[]
  notifyAll?: boolean
  active: boolean
  canCreateEntities?: boolean
  scanSpecificDomains?: string[]
  isSourceOfTruth?: boolean
  scan?: {
    unit: string
    value: number
  }
}

export interface ConversationWidget {
  total: number
  totalRisk: number
  totalSensitive: number
}

export interface ConversationWidgetParams extends ImMessageApiQueryParams {
  page: number
}

/** Templates */
export interface IGetTemplatesParams {
  page: number
}

/** Identifiers */
export interface IGetIdentifiersParams extends IdentifiersParams {
  page?: number
}

/** Residencies */
export interface IResidency {
  name: string
  filesCount: number
  riskyFilesCount: number
  entitiesCount: number
  lastUpdated: string
}

export interface IUserEntity extends UserEntity {
  dynamicPiiData?: {
    [key: string]: Array<string>
  }
  residencies?: string[]
}

/** Policies */
export interface IPolicyType {
  id: string
  name: POLICY_TYPES
  prefix: POLICY_CLASSIFICATIONS
  policiesCount?: number
  datasourcesCount?: number
  alertsCount?: number
  permittedObjectsCount?: number
  permittedAttributesCount?: number
  permittedEntitiesCount?: number
  description?: string
}
export interface IPolicyTypeForPrint {
  name: string
  type: string
  prefix: string
  filterIds?: string[]
  id?: string
  datasources?: { name: string; type: string }[]
  objectsCount?: number
  entitiesCount?: number
}
export interface IPolicyFilterId {
  profileIds?: string[] | null
  filterIds: string[]
}

export enum RuleSetStatuses {
  active = 'ACTIVE',
  deleting = 'DELETING'
}
export interface RulsetInfo {
  id: string
  policyTypeId?: string
  type?: string
  prefix?: string
  name: string
  description?: string
  alertsCount: number
  policiesCount?: number
  datasourcesCount?: number
  permittedObjectsCount?: number
  permittedAttributesCount?: number
  permittedEntitiesCount?: number
  createdAt?: string
  status?: RuleSetStatuses
}

export interface IPolicy {
  id: string
  name: string
  severity: SEVERITY_LEVEL
  regulations: REGULATIONS[]
  status: boolean
  policyFilters: IPolicyFilterId[]
  policyActions?: string[]
  automationActions?: AutomationConfig[]
  notifyUsers?: string[]
  policyType?: string
  policyTypeId?: string
  prefix?: string
  description?: string
  permittedObjectsCount?: number
  permittedAttributesCount?: number
  permittedEntitiesCount?: number
  alertsCount?: number
  attributeCondition?: AlertAttributeCondition[]
  flagOnlySourceOfTruthVerified?: boolean
  alertAssignment?: ALERT_ASSIGMENT_OPTIONS
  enableAlerts?: boolean
  policyFrequencyConfiguration?: IPolicyFrequencyConfiguration
}

export interface IPolicyException {
  id: string
  dataSource: string
  exclude: string
  condition: string
  values: string[]
}

export interface IException
  extends Pick<IPolicyException, 'dataSource' | 'exclude' | 'condition' | 'values'> {
  policyId: string
}

export interface IGetPolicyAlertsParams {
  page: number
  pageSize?: number
  severity?: SEVERITY_LEVEL | undefined
  datasourceType?: DATA_SOURCE_TYPES
  alertStatus?: ALERT_STATUS[]
  policyType?: POLICY_TYPES
  isUnresolved?: boolean
  isResolved?: boolean
  datasourceId?: string
  partnerIds?: string[]
  ruleSetIds?: string
  subAlertAssignees?: string[]
}

export type FolderInfo = {
  id?: string
  path?: string
  name?: string
  webUrl?: string
}
export type IAlertObjectsImpacted = {
  id: string
  attributesList?: Array<{ name: string; internalName: string }>
  entitiesList: Array<{ name: string; id: string }>
  groupsList: Array<{ name: string; id: string }>
  usersList: Array<{ name: string; id: string }>
  readAccessList: Array<{ name: string; id: string }>
  writeAccessList: Array<{ name: string; id: string }>
  documentId?: string
  documentName?: string
  classification?: string
  createdBy?: string
  lastModified?: string
  createdAt?: string
  owner?: string
  dataSourceId?: string
  dataSourceName?: string
  dataSourceType?: DATA_SOURCE_TYPES
  objectLink?: string
  assignee?: string
  generatedAt?: string
  tableClusterName?: string

  // bucket
  bucketName?: string
  bucketOwner?: string

  // ticket
  ticketId?: string
  jiraTicketId?: string
  ticketName?: string
  ticketLink?: string
  ticketReporter?: string
  ticketAssignee?: string
  projectId?: string
  projectName?: string
  sharedOn?: string
  attachments?: { type: string; name: string; id: string }[]

  // mail
  messageId?: string
  mailTo?: string[]
  mailFrom?: string
  mailSubject?: string
  folder?: FolderInfo
  type?: MAIL_FOLDER_TYPE
  refMailboxIds?: string[]

  // channel
  channelId?: string
  channelName?: string
  channelPiiCount?: number
  channelMembersCount?: number
  channelType?: CHANNEL_TYPES

  // access control
  users?: AccessControlUserListItem[]
  groups?: AccessControlUserListItem[]
  userAccessCount?: number
  groupAccessCount?: number
}

export interface IPolicyAlert {
  id: string
  name: string
  severity: SEVERITY_LEVEL
  objects: number
  entities: Array<{ entityId: string; name: string }>
  entitiesCount?: number
  dataSource: string
  dataSourceType?: DATA_SOURCE_TYPES
  dataSourceId?: string
  assignees?: string[]
  assigneesCount?: number
  policyName?: string
  status?: ALERT_STATUS
  createdAt?: string
  lastModified?: string
  policyId?: string
  policyTypeId?: string
  policy?: string
  description?: string
  category?: string
}

export interface IPolicyFilterProfile {
  id: string
  labelDisplayName: string
}

export interface IPolicyFilter {
  id: string
  name: string
  owner: string
  createdAt: string
  profiles?: IPolicyFilterProfile[]
}

export interface IGetFiltersParams {
  type?: string
}

export interface IFilteredFileInfo {
  name: string
  totalRecordCount: number
  filteredRecordCount: number
}

export interface IApplyFilterParams {
  id: string
  conditions: string[]
}

export interface IProfileMapData {
  [key: string]: string
}

export enum CONDITION_COMBINATOR {
  OR = 'OR',
  AND = 'AND'
}

export interface ICreateFilterParams {
  profileMapId?: string
  owner?: string
  datasourceId?: string
  name?: string
  type?: string
  conditions?: Array<{
    filterExpression: string
    conditionLabel: string
  }>
}

export interface IProfileMapColumn {
  sourceField: string
  sourceFieldIndex: string
  isSensitive: boolean
  attribute: string
  confidence: string
  changesStatus?: COLUMN_CHANGES
}

export interface IPageInfo {
  startCursor?: string
  endCursor?: string
  hasNextPage?: boolean
  hasPreviousPage?: boolean
  page?: number
}

export interface IUpdateProfileMapItem {
  sourceField?: string
  sourceFieldIndex?: string
  isSensitive?: boolean
  attribute?: string
  confidence?: number
}

export interface ICreateDatasourceFilterParams {
  datasourceIds: string[]
  anyDatasource: boolean
  scanConditions: DatasourceScanConditionsFilter[]
}

export interface ICreateDocumentLabelSetFilterParams {
  documentLabelSetId: string
}

interface IPolicyFrequencyConfiguration {
  type: 'MONTH' | 'YEAR'
  value: number
  startFrom: string
  runOn: {
    day?: number
    type?: string
  }
}

interface IPolicyMutationParams {
  severity: SEVERITY_LEVEL
  description?: string
  policyFilterIds: string[]
  datasourceActionIds?: string[]
  profileIds: string[]
  enableAlerts?: boolean
  regulations?: REGULATIONS[]
  notifyUsers: string[]
  generateAlertSettings?: AlertAttributeCondition[]
  flagOnlySourceOfTruthVerified: boolean
  alertAssignment?: ALERT_ASSIGMENT_OPTIONS
  policyFrequencyConfiguration?: IPolicyFrequencyConfiguration | null
}

export interface ICreatePolicyParams extends IPolicyMutationParams {
  name: string
  policyType: string
  description?: string
}

export interface IUpdatePolicyParams extends IPolicyMutationParams {
  id: string
  policyType: string
}
export type LabelGroup = {
  operator: CriteriaGroupOperatorTypes
  documentLabels: {
    labelSetId: string
    labelId: string
  }[]
}
export type DocumentLabelGroup = {
  operator: CriteriaOperatorTypes
  groups: LabelGroup[]
}

export interface IPolicyFilterState {
  list?: IPolicyFilter[]
  datasourceFilterCreateId?: string
  documentLabelSetFilterCreateId?: string
  attributeSetFilterCreateId?: string
  durationFilterCreateId?: string
  documentLabelFilterCreateId?: string
  documentFilterCreateId?: string
  accessFilterCreateId?: string
  dataSourceIds?: string[]
  datsourceScanConditions?: DatasourceScanConditionsFilter[]
  attributeSetIds?: string[]
  documentLabelSetId?: string
  profileFilterIds?: string[]
  profileIds?: string[]
  filtersLoaded?: boolean
  attributeSets?: AttributeSet[]
  datasources?: DataSource[]
  durationCount?: number
  durationType?: string
  attributeType?: RETENTION_ATTRIBUTE_TYPES
  documentLabelGroups?: DocumentLabelGroup
  rulesetGroups?: {
    operator: CriteriaOperatorTypes
    groups: Ruleset[]
  }
  accessType?: ACCESS_CONTROL_TYPES
  accessSubTypes?: OPEN_ACCESS_TYPES[]
  excessiveAccessUserCount?: number
}

export interface IPolicyDataSourceId {
  id?: string
}

export interface IUpdateFilter {
  id: string
  dataSourceIds?: string[]
  attributeSetIds?: string[]
  documentLabelSetId?: string
  anyDatasource?: boolean
  value?: number
  valueType?: string
  attributeType?: RETENTION_ATTRIBUTE_TYPES
  scanConditions?: DatasourceScanConditionsFilter[]
}

export interface IDeletePolicyParams {
  id: string
}

export interface IQueryCursor {
  first?: number
  after?: string
}

export interface IPolicyListByTypeParam extends IQueryCursor {
  policyType?: string
}

export interface UserDetails {
  id: string
  firstName: string
  lastName: string
  emailId: string
  accountType: string
  loggedInViaSaaS: boolean
  roles: {
    name: UserRoles
    description: string
    accessLevels: { accessLevel: AccessLevelRoles; enabled: boolean }[]
  }[]
  isVerified: boolean
  createdAt: string
  lastModifiedAt: string
  lastActiveAt: string
  isImmutable: boolean
  language: string
}
/** Relationships */
export type TableRelationship = {
  tableId: string
  sourceColumnId: string
  targetColumnId: string
}

export type FilterParams = {
  filter?: {
    key: string
    values: string[]
  }[]
  booleanFilter?: {
    key: string
    value: boolean
  }[]
  dateFilter?: {
    key?: string
    start?: number
    end?: number
  }
  sortBy?: string
  datasourceIds?: string[]
  documentLabelIds?: string[]
}

export type IMailInfo = {
  mailObjectId?: string
  attributesCount?: number
  entitiesCount?: number
  isDeleted?: boolean
  messageId?: string
  subject?: string
  sender?: string
  rowId?: string
  markedForRescan?: boolean
  attributes?: { name: string; internalName: string }[]
  attributeInstanceCount?: number
  entities?: { name: string; id: string }[]
  attachments?: { type: string; name: string; id: string }[]
  alerts?: { name: string; id: string; severity: SEVERITY_LEVEL }[]
  sharedAt?: string
  refMailboxIds?: string[]
  folder?: { folderName: string }[]
  inboxMailFolders?: { isTrashFolder: boolean; emailId: string }[]
}

export interface IDocument extends Document {
  datasourceId?: string
  datasourceType?: DATA_SOURCE_TYPES | string
  datasourceName?: string
  objectsCount?: number
  structuredObjectsCount?: number
  riskyObjectsCount?: number
  sensitiveObjectsCount?: number
}

export interface CreateRopaParams {
  processId: string
  name: string
  owner: string
  dueDate: string
}

export interface DownloadListParams {
  reportFormat: DownloadReportFormats
  reportType: DownloadReportTypes
  filter?: {
    key: string
    values: string[] | string
  }[]
  booleanFilter?: {
    key: string
    value: boolean
  }[]
  dateFilter?: {
    key?: string
    start?: number
    end?: number
  }
  parameters: Array<{
    key: string
    values: string | string[]
  }>
  dateFilterList?: Array<{
    key?: string
    start?: string
    end?: string
  }>
  isAsync?: boolean
}

export interface DownloadPdfParams {
  reportFormat: DownloadReportFormats
  reportType: DownloadReportTypes
  parameters: Array<{
    [key: string]: string | boolean
  }>
  isAsync?: boolean
}

export type PromiseResult<T> = { status: 'fulfilled' | 'rejected'; value?: T; reason?: any }
